export function SelectedWriting() {
  return (
    <div className="SelectedWritingSection">
      <p className="Publication">
        <span className="Label">Criticism</span>
        <span className="PublicationName">
          <a
            href="https://van-magazine.com/mag/jeanine-tesori-grounded-metropolitan-opera-review/"
            target="_blank"
            rel="noopener noreferrer"
          >
            "Mysteries of the Military-Industrial Complex"
          </a>
          : On <i>Grounded</i> at the Metropolitan
          Opera&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<i>VAN Magazine</i>
        </span>
      </p>
      <p className="Publication">
        <span className="Label">Criticism</span>
        <span className="PublicationName">
          <a
            href="https://www.thedriftmag.com/mention/issue-14/"
            target="_blank"
            rel="noopener noreferrer"
          >
            On <i>Like Water for Chocolate</i>&nbsp;at American Ballet Theatre
          </a>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<i>The Drift</i>
        </span>
      </p>
      <p className="Publication">
        <span className="Label">Interview</span>
        <span className="PublicationName">
          <a
            href="https://theadroitjournal.org/issue-fifty-one/a-conversation-with-garth-greenwell/"
            target="_blank"
            rel="noopener noreferrer"
          >
            "A Conversation with Garth Greenwell"
          </a>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <i>The Adroit Journal</i>
        </span>
      </p>
    </div>
  );
}
